import { configureEnvironment } from './env';
import React from 'react';
import ReactDOM from 'react-dom';

void configureEnvironment()
  .then(async () => {
    const Root = (await import('./Root')).default;
    const { setRedirectURL } = await import('./util/urlRedirect');
    const currentURL = window.location.pathname + window.location.search + window.location.hash;
    setRedirectURL(currentURL);
    return <Root />;
  })
  .catch(() => {
    return <div>Application failed to load</div>;
  })
  .then((it) => {
    ReactDOM.render(it, document.getElementById('root'));
  });
