"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APIError = void 0;
const error_1 = require("../util/error");
class APIError {
    constructor(status, message, error, responseBody) {
        this.status = status;
        this.message = message || (error === null || error === void 0 ? void 0 : error.message) || '';
        this.name = 'APIError';
        this.stack = (0, error_1.getStackTrace)(error);
        this.responseBody = responseBody;
    }
}
exports.APIError = APIError;
