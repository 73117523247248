"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.londonTZ = exports.months = exports.time = exports.Month = void 0;
var Month;
(function (Month) {
    Month["JANUARY"] = "January";
    Month["FEBRUARY"] = "February";
    Month["MARCH"] = "March";
    Month["APRIL"] = "April";
    Month["MAY"] = "May";
    Month["JUNE"] = "June";
    Month["JULY"] = "July";
    Month["AUGUST"] = "August";
    Month["SEPTEMBER"] = "September";
    Month["OCTOBER"] = "October";
    Month["NOVEMBER"] = "November";
    Month["DECEMBER"] = "December";
})(Month = exports.Month || (exports.Month = {}));
exports.time = Object.values(Month);
exports.months = exports.time;
exports.londonTZ = 'Europe/London';
