import { setupTimeGetter } from './util/time';
import loadEnv from 'application.env';
import { setupLogger, LogLevel } from 'lib-common';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ApplicationEnv {
    interface Env {
      PAPI_EE_REPORTER?: string;
      API_GATEWAY_URL: string;
      SAP_CDC_CLIENT_ID: string;
      SAP_CDC_DOMAIN: string;
      SAP_CDC_LOGOUT: string;
      STUB_TIME?: string;
      LOG_LEVEL?: keyof LogLevel;
      IDLE_TIMEOUT_SEC: string;
      NODE_ENV?: string;
      SAPI_EE_PRIMER?: string;
      UNDER_MAINTENANCE?: string;
      FEATURE_FLAGS_URL: string;
      FEATURE_FLAGS_CLIENT_KEY: string;
    }
  }
}

export const configureEnvironment = (): Promise<ApplicationEnv.Env> =>
  loadEnv().then(async () => {
    if (window.env.NODE_ENV === 'development') {
      await loadEnv({ path: '/local.env', failSilently: true });
    }
    setupLogger({ level: window.env.LOG_LEVEL });
    setupTimeGetter();
    logger.debug({
      message: 'application.env',
      data: window.env,
    });
    return window.env;
  });
